import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { NextRouter, useRouter } from "next/router";
import PropTypes from "prop-types";
import { LDProvider } from "launchdarkly-react-client-sdk";

import { ApolloClient, useApolloClient } from "@apollo/client";
import { useAuth } from "shared/hooks/use-auth";

import { clearUserInfo, isExpiredJWT } from "shared/utils/savvy-auth";

import {
  UserConfirmationStatus,
  UserProfile,
  UserRole,
} from "shared/generated/graphql";
import {
  LOGIN_CONTEXT_PARAM,
  getClientStageRedirectRoute,
} from "shared/utils/routing";
import { AuthContextValue } from "shared/contexts/jwt-context";
import assert from "assert";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";

type AuthGuardProps = {
  children: ReactNode;
  forAdmin?: boolean;
  forAdvisor?: boolean;
  forClient?: boolean;
};

type CheckAuthProps = {
  jwtToken: string | null;
  apolloClient: ApolloClient<object>;
  auth: AuthContextValue;
  router: NextRouter;
  setChecked: (checked: boolean) => void;
  forAdmin?: boolean;
  forAdvisor?: boolean;
  forClient?: boolean;
  isSmallScreen: boolean;
};

export function loginRedirectQuery(
  router: NextRouter,
  extraParams: Record<string, string> = {}
) {
  const searchString = window.location.search;
  const searchParams = new URLSearchParams(searchString);

  // Only relevant for login page, TODO: consolidate on approach here
  const loginContext = searchParams.get("login_context");
  searchParams.delete("login_context");
  const loginToken = searchParams.get(LOGIN_CONTEXT_PARAM);
  searchParams.delete(LOGIN_CONTEXT_PARAM);

  for (const [key, value] of Object.entries(extraParams)) {
    if (value) {
      searchParams.set(key, value);
    }
  }

  type AuthGuardUrlQuery = {
    returnUrl: string;
    login_context?: string;
    [LOGIN_CONTEXT_PARAM]?: string;
  };
  // TODO: use NextRouter to improve this logic
  const query: AuthGuardUrlQuery = {
    returnUrl: `${router.asPath.split("?")[0]}${
      Array.from(searchParams.keys()).length > 0
        ? `?${searchParams.toString()}`
        : ""
    }`,
  };

  if (loginContext) {
    query.login_context = loginContext;
  }
  if (loginToken) {
    query[LOGIN_CONTEXT_PARAM] = loginToken;
  }
  return query;
}

function checkAuth({
  jwtToken,
  apolloClient,
  auth,
  router,
  setChecked,
  forAdmin,
  forAdvisor,
  forClient,
  isSmallScreen,
}: CheckAuthProps) {
  const isExpiredAuth = isExpiredJWT(jwtToken);
  if (isExpiredAuth) {
    clearUserInfo(apolloClient);
    window.location.reload();
  } else if (!auth.isAuthenticated) {
    void router.push({
      pathname: "/login",
      query: loginRedirectQuery(router),
    });
    return;
  } else if (
    auth.user?.confirmationStatus ===
    UserConfirmationStatus.PendingEmailConfirmation
  ) {
    // Already on email conf page, no redirect needed
    if (router.basePath + router.pathname === "/confirm-email") {
      setChecked(true);
    } else {
      void router.push({
        pathname: "/confirm-email",
        query: { returnUrl: router.asPath },
      });
      return;
    }
  } else if (
    forClient &&
    auth.user?.profileType === UserProfile.Client &&
    auth.user.clientStage
  ) {
    const stageRedirectRoute = getClientStageRedirectRoute(
      router,
      auth.user,
      isSmallScreen
    );
    if (stageRedirectRoute) {
      void router.replace(stageRedirectRoute);
      return;
    }
    setChecked(true);
  } else if (forAdvisor && auth.user?.profileType !== UserProfile.Advisor) {
    void router.push({
      pathname: "/dashboard",
    });
    return;
  } else if (forAdmin && auth.user?.role !== UserRole.Admin) {
    void router.push({
      pathname: "/",
    });
    return;
  } else if (forClient && auth.user?.profileType !== UserProfile.Client) {
    void router.push({
      pathname: "/advisor/dashboard/clients",
    });
    return;
  } else {
    setChecked(true);
  }
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, forAdmin, forAdvisor, forClient } = props;
  const auth = useAuth();
  const router = useRouter();
  const apolloClient: ApolloClient<object> = useApolloClient();
  const [checked, setChecked] = useState(false);
  const jwtToken = window.localStorage.getItem("accessToken");
  const isSmallScreen = useIsSmallScreen();

  useEffect(
    () => {
      setInterval(() => {
        const isExpiredAuth = isExpiredJWT(jwtToken);
        if (isExpiredAuth) {
          window.location.href = `/login?returnUrl=${window.encodeURIComponent(
            router.asPath
          )}&reAuth=true`;
        }
      }, 500);

      if (!router.isReady) {
        return;
      }
      checkAuth({
        jwtToken,
        apolloClient,
        auth,
        router,
        setChecked,
        forAdmin,
        forAdvisor,
        forClient,
        isSmallScreen,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady]
  );

  if (!checked) {
    return null;
  }

  const clientSideID = process.env["NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID"];
  assert(
    clientSideID,
    "NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID must be set in .env"
  );

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // Authenticated / authorized.
  return (
    <LDProvider
      clientSideID={clientSideID}
      context={{
        kind: "user",
        key: auth.user?.id?.toString(),
        name: `${auth.user?.firstName} ${auth.user?.lastName}`,
        email: auth.user?.email,
      }}
    >
      {children}
    </LDProvider>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
